<template>
  <div id="Opppryunities">
    <div class="banner-con">
      <div class="top-left-con">
        <div class="line-one" v-visible="false"></div>
        <div class="line-two" v-visible="false"></div>
        <div class="line-three"></div>
        <p class="bg-tit1">
          {{ $t('Jobs.tit3') }}
        </p>
        <p class="bg-tit2">
          {{ $t('Jobs.tit4') }}
          <span>
            <img style="width: 90px" src="../assets/img/2-Icons/icons_connect-wh.svg" alt="" />
          </span>
        </p>
      </div>
      <swiper :options="swiperOption" class="swiper" ref="mySwiper">
        <swiper-slide v-for="(item, index) in swiperImg" :key="index" class="swiper-item1">
          <div class="box">
            <img class="img" :src="item.img" alt="" />
          </div>
          <!-- <div class="top-right-con"></div> -->
          <div class="bottom-left-con"></div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination" />
      </swiper>
    </div>
    <div class="contain" style="max-width: 1200px; margin: 0 auto">
      <el-radio-group :disabled="inputDisable" class="radio-group" v-model="form.type" style="margin-bottom: 30px">
        <el-radio-button label="p">
          {{ $t('Jobs.paid') }}
        </el-radio-button>
        <el-radio-button label="v">
          {{ $t('Jobs.voluntary') }}
        </el-radio-button>
      </el-radio-group>
      <div class="createForm">
        <div class="center-background">
          <div class="formgroup">
            <div class="group-name">{{ $t('Jobs.information') }}</div>
            <el-row :gutter="20">
              <el-col :span="8"
                ><div class="col-class">
                  <el-input :disabled="inputDisable" v-model="form.organisation_name_zh_tw" :placeholder="$t('Jobs.placeholder1') + '(CN)'"></el-input></div
              ></el-col>
              <el-col :span="8"
                ><div class="col-class">
                  <el-input :disabled="inputDisable" v-model="form.event_name_zh_tw" :placeholder="$t('Jobs.placeholder3') + '(CN)'"></el-input></div
              ></el-col>
              <el-col :span="8"
                ><div class="col-class">
                  <el-input :disabled="inputDisable" v-model="form.person_in_charge" :placeholder="$t('Jobs.placeholder2')"></el-input></div
              ></el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8"
                ><div class="col-class">
                  <el-input :disabled="inputDisable" v-model="form.organisation_name_en" :placeholder="$t('Jobs.placeholder1') + '(EN)'"></el-input></div
              ></el-col>
              <el-col :span="8"
                ><div class="col-class">
                  <el-input :disabled="inputDisable" v-model="form.event_name_en" :placeholder="$t('Jobs.placeholder3') + '(EN)'"></el-input></div
              ></el-col>
            </el-row>
          </div>
          <div class="formgroup">
            <div class="group-name">{{ $t('Jobs.information') }}</div>
            <el-row :gutter="20">
              <el-col :span="12">
                <div class="col-class">
                  <el-select :disabled="inputDisable" v-model="form.event_type" :placeholder="$t('Jobs.placeholder4')">
                    <el-option v-for="item in eventsTypes" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="col-class">
                  <el-select :disabled="inputDisable" :placeholder="$t('Jobs.placeholder5')" v-model="form.sports_type" style="width: 100%">
                    <el-option v-for="item in sportType" :no-data-text="$t('Jobs.placeholder6')" :key="item.name_en" :label="item.name" :value="item.name_en"> </el-option>
                  </el-select>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <div class="col-class">
                  <el-date-picker
                    :disabled="inputDisable"
                    value-format="yyyy-MM-dd"
                    v-model="date"
                    type="daterange"
                    range-separator="-"
                    :start-placeholder="$t('Jobs.placeholder7')"
                    :end-placeholder="$t('Jobs.placeholder8')"
                  >
                  </el-date-picker>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="col-class">
                  <el-select :disabled="inputDisable" :placeholder="$t('Jobs.placeholder9')" v-model="form.class_frequency" style="width: 100%">
                    <el-option v-for="item in classFrequency" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                  </el-select>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <div class="col-class">
                  <el-time-picker
                    :disabled="inputDisable"
                    is-range
                    v-model="time"
                    format="HH:mm"
                    value-format="HH:mm"
                    range-separator="-"
                    :start-placeholder="$t('Jobs.placeholder10')"
                    :end-placeholder="$t('Jobs.placeholder11')"
                    :placeholder="$t('Jobs.placeholder12')"
                  >
                  </el-time-picker>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="col-class">
                  <el-input :disabled="inputDisable" v-model="form.lesson" :placeholder="$t('Jobs.placeholder13')"></el-input>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8"
                ><div class="col-class">
                  <el-select :disabled="inputDisable" v-model="form.location" :placeholder="$t('Jobs.placeholder14')" no-data-text="No Data">
                    <el-option v-for="item in areas" :no-data-text="$t('Jobs.placeholder6')" :key="item.id" :label="styleLang == 'cn' ? item.name_zh_tw : item.name_en" :value="item.id"> </el-option>
                  </el-select></div
              ></el-col>
              <el-col :span="8"
                ><div class="col-class">
                  <el-select :disabled="inputDisable" v-model="form.target" :placeholder="$t('Jobs.placeholder15')">
                    <el-option v-for="item in Target" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                  </el-select></div
              ></el-col>
              <el-col :span="8"
                ><div class="col-class">
                  <el-select :disabled="inputDisable" v-model="form.class_size" :placeholder="$t('Jobs.placeholder16')">
                    <el-option v-for="item in classSize" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                  </el-select></div
              ></el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <div class="col-class">
                  <el-select :disabled="inputDisable" v-model="form.skill_level" :placeholder="$t('Jobs.placeholder17')">
                    <el-option v-for="item in skillLevel" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="16">
                <div class="col-class">
                  <el-select :disabled="inputDisable" :placeholder="$t('Jobs.placeholder18')" v-model="form.medium_of_instruction" style="width: 100%">
                    <el-option v-for="item in mediumOfInstruction" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                  </el-select>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <div class="col-class">
                <el-col :span="24">
                  <el-input :disabled="inputDisable" type="textarea" rows="3" :placeholder="$t('Jobs.placeholder23') + '(CN)'" v-model="form.description_zh_tw" autocomplete="off"></el-input>
                </el-col>
              </div>
            </el-row>
            <el-row :gutter="20">
              <div class="col-class">
                <el-col :span="24">
                  <el-input :disabled="inputDisable" type="textarea" rows="3" :placeholder="$t('Jobs.placeholder23') + '(EN)'" v-model="form.description_en" autocomplete="off"></el-input>
                </el-col>
              </div>
            </el-row>
          </div>
          <div class="formgroup" v-if="form.type == 'p'">
            <div class="group-name">{{ $t('Jobs.coachrequest') }}</div>
            <el-row :gutter="20">
              <el-col :span="8"
                ><div class="col-class">
                  <el-select :disabled="inputDisable" v-model="form.is_disclose" :placeholder="$t('Jobs.placeholder20')">
                    <el-option v-for="item in CoachingFeePublic" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                  </el-select></div
              ></el-col>
              <el-col :span="8" v-if="form.is_disclose">
                <div class="col-class">
                  <el-input :disabled="inputDisable" type="number" :placeholder="$t('CreateProfiles.Perhour')" v-model="form.coach_fee"></el-input></div
              ></el-col>
              <el-col :span="8"
                ><div class="col-class">
                  <el-select :disabled="inputDisable" v-model="form.coaching_experiences" :placeholder="$t('Jobs.placeholder21')">
                    <el-option v-for="item in coachingExperiences" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                  </el-select></div
              ></el-col>
            </el-row>
          </div>
          <div class="formgroup" v-else>
            <div class="group-name">{{ $t('Jobs.coachrequest') }}</div>
            <el-row :gutter="20">
              <el-col :span="8"
                ><div class="col-class">
                  <el-input :disabled="inputDisable" type="number" min="0" v-model="form.volunteer_hours" :placeholder="$t('Jobs.placeholder22')"> </el-input></div
              ></el-col>
            </el-row>
          </div>
          <div class="formgroup">
            <div class="group-name">{{ $t('Jobs.txtInfo4') }}</div>
            <el-row :gutter="20">
              <el-col :span="24">
                <div class="col-class">
                  <el-input :disabled="inputDisable" rows="5" type="textarea" :placeholder="$t('Jobs.placeholder19')" v-model="form.other_description" autocomplete="off"></el-input>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="formgroup upload">
            <div class="upload-text" v-if="!form.banner_url && !imageUrl">
              {{ $t('Jobs.upload') }}
            </div>
            <div class="banner-url" v-if="form.banner_url">
              <img class="bannerwidth" :src="form.banner_url" alt="" />
            </div>
            <div class="banner-url" v-if="imageUrl">
              <img class="bannerwidth" :src="imageUrl" alt="" />
            </div>
            <div class="plus-btn" @click="dialogFormVisible = true" v-if="!inputDisable">
              <i class="el-icon-plus" />
            </div>
            <el-dialog :title="$t('Jobs.upload')" :visible.sync="dialogFormVisible">
              <el-form>
                <el-form-item>
                  <el-upload
                    :disabled="inputDisable"
                    ref="upload"
                    action="#"
                    list-type="picture-card"
                    :limit="1"
                    :multiple="false"
                    :on-remove="handleRemove"
                    :on-change="handleChange"
                    :auto-upload="false"
                    :before-upload="beforeUpload"
                    :http-request="uploadPhotos"
                    :file-list="fileList"
                  >
                    <i class="el-icon-plus"></i>
                    <!-- <i
                                            v-if="fileList.length < 1"
                                            slot="default"
                                            class="el-icon-plus"
                                        /> -->
                  </el-upload>
                  <el-radio-group v-model="bannerRadio" size="mini">
                    <template>
                      <el-row :gutter="20">
                        <el-col :span="6" class="image-radios" v-for="(image, index) in imagebanners" :key="index">
                          <el-radio-button :label="image.url"><img class="banner-choose" :src="image.url" /></el-radio-button>
                        </el-col>
                      </el-row>
                    </template>
                  </el-radio-group>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">{{ $t('CreateProfiles.cancel') }}</el-button>
                <el-button type="primary" :loading="BtnLoading" @click="isFileChange ? uploadPic() : save()">{{ $t('CreateProfiles.save') }}</el-button>
              </div>
            </el-dialog>
          </div>
          <div class="condition">
            <el-checkbox name="type" label="term" v-model="isFirstTermSelected">
              {{ $t('CreateProfiles.terms') }}
            </el-checkbox>
            <el-checkbox name="type" label="term" v-model="isSecondTermSelected">
              {{ $t('CreateProfiles.terms2') }}
            </el-checkbox>

            <p class="verify" v-html="$t('CreateProfiles.verify_terms')"></p>
          </div>
          <div class="submit" v-if="!inputDisable">
            <el-button class="submit-btn" type="primary" @click="submit()">{{ $t('Jobs.submit') }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { submitJob, GetArea, GetJob, editJob } from '@/api/opportunities'
import { getSportTypes } from '@/api/coachProfile'
import { S3Api } from '@/api/fileUtility'
export default {
  name: 'CreateJob',
  data() {
    return {
      terms: true,
      inputDisable: false,
      styleLang: 'cn',
      dialogFormVisible: false,
      file: null,
      fileList: [],
      isFileChange: false,
      BtnLoading: false,
      date: null,
      time: null,
      form: {
        type: 'p',
        is_disclose: null,
        organisation_name_zh_tw: '',
        organisation_name_en: '',
        person_in_charge: '',
        sport_type_id: 'b6592eb6-0d1a-48b9-8d60-6a23ccdaa3b4',
        event_name_zh_tw: '',
        event_name_en: '',
        event_type: '',
        sports_type: '',
        starting_date: '',
        class_frequency: '',
        class_duration_start: '',
        class_duration_end: '',
        lesson: '',
        location: '',
        target: '',
        class_size: '',
        skill_level: '',
        medium_of_instruction: '',
        description_zh_tw: '',
        description_en: '',
        coach_fee: '',
        coaching_experiences: '',
        other_description: '',
        banner: '',
      },
      tabPosition: 'paid',
      swiperOption: {
        loop: true,
        speed: 1500,
        pagination: {
          el: '.swiper-pagination',
        },
      },
      swiperImg: [
        {
          img: require('../assets/img/0-Banner/20211120-122.jpg'),
        },
      ],
      eventsTypes: [
        {
          value: 'Regular Training Course',
          label: this.$t('Jobs.eventsTypes1'),
        },
        {
          value: 'One-off Event',
          label: this.$t('Jobs.eventsTypes2'),
        },
      ],
      sportType: [],
      CoachingFeePublic: [
        {
          value: true,
          label: this.$t('CreateProfiles.public'),
        },
        {
          value: false,
          label: this.$t('CreateProfiles.unpublic'),
        },
      ],
      classFrequency: [
        {
          value: 'Every Monday',
          label: this.$t('Jobs.classFrequency1'),
        },
        {
          value: 'Every Tuesday',
          label: this.$t('Jobs.classFrequency2'),
        },
        {
          value: 'Every Wednesday',
          label: this.$t('Jobs.classFrequency3'),
        },
        {
          value: 'Every Thursday',
          label: this.$t('Jobs.classFrequency4'),
        },
        {
          value: 'Every Friday',
          label: this.$t('Jobs.classFrequency5'),
        },
        {
          value: 'Every Saturday',
          label: this.$t('Jobs.classFrequency6'),
        },
        {
          value: 'Every Sunday',
          label: this.$t('Jobs.classFrequency7'),
        },
        {
          value: 'Everyday',
          label: this.$t('Jobs.classFrequency8'),
        },
        {
          value: 'Every Week',
          label: this.$t('Jobs.classFrequency9'),
        },
        {
          value: 'Every Second Week',
          label: this.$t('Jobs.classFrequency10'),
        },
        {
          value: 'Every Month',
          label: this.$t('Jobs.classFrequency11'),
        },
        {
          value: 'To Be Confirmed',
          label: this.$t('Jobs.classFrequency12'),
        },
      ],
      Target: [
        {
          value: 'Primary School',
          label: this.$t('CreateProfiles.coachingTarget1'),
        },
        {
          value: 'Secondary School',
          label: this.$t('CreateProfiles.coachingTarget2'),
        },
        {
          value: 'Parent-child',
          label: this.$t('CreateProfiles.coachingTarget3'),
        },
        {
          value: 'Elderly',
          label: this.$t('CreateProfiles.coachingTarget4'),
        },
        {
          value: 'Others',
          label: this.$t('CreateProfiles.coachingTarget5'),
        },
      ],
      classSize: [
        {
          value: '2-5',
          label: '2-5',
        },
        {
          value: '5-10',
          label: '5-10',
        },
        {
          value: '10-15',
          label: '10-15',
        },
        {
          value: '15-20',
          label: '15-20',
        },
        {
          value: '20-25',
          label: '20-25',
        },
        {
          value: '25-30',
          label: '25-30',
        },
        {
          value: 'Others',
          label: this.$t('CreateProfiles.coachingTarget5'),
        },
      ],
      skillLevel: [
        {
          value: 'No Relevant Experiences',
          label: this.$t('Jobs.skillLevel1'),
        },
        {
          value: 'Introductory',
          label: this.$t('Jobs.skillLevel2'),
        },
        {
          value: 'Intermediate',
          label: this.$t('Jobs.skillLevel3'),
        },
        {
          value: 'Advanced',
          label: this.$t('Jobs.skillLevel4'),
        },
        {
          value: 'Professional',
          label: this.$t('Jobs.skillLevel5'),
        },
      ],
      mediumOfInstruction: [
        {
          value: 'Cantonese',
          label: this.$t('Jobs.mediumOfInstruction1'),
        },
        {
          value: 'Putonghua',
          label: this.$t('Jobs.mediumOfInstruction2'),
        },
        {
          value: 'English',
          label: this.$t('Jobs.mediumOfInstruction3'),
        },
        {
          value: 'Others',
          label: this.$t('CreateProfiles.coachingTarget5'),
        },
      ],
      coachFee: [
        {
          value: 'Below Market Price',
          label: this.$t('CreateProfiles.forCoachingFee3'),
        },
        {
          value: 'Average Market Price',
          label: this.$t('CreateProfiles.forCoachingFee2'),
        },
        {
          value: 'Above Market Price',
          label: this.$t('CreateProfiles.forCoachingFee1'),
        },
        {
          value: 'Negoiable',
          label: this.$t('Jobs.coachFee1'),
        },
      ],
      coachingExperiences: [
        {
          value: 'Physical Disability',
          label: this.$t('Jobs.coachingExperiences1'),
        },
        {
          value: 'Visual Impairment',
          label: this.$t('Jobs.coachingExperiences2'),
        },
        {
          value: 'Hearing Impairment',
          label: this.$t('Jobs.coachingExperiences3'),
        },
        {
          value: 'Attention Deficit / Hyperactivity Disorder (ADHD)',
          label: this.$t('Jobs.coachingExperiences4'),
        },
        {
          value: 'Autism Spectrum Disorder (ASD)',
          label: this.$t('Jobs.coachingExperiences5'),
        },
        {
          value: 'Specific Learning Difficulties (SpLD)',
          label: this.$t('Jobs.coachingExperiences6'),
        },
        {
          value: 'Speech and Language Impairment',
          label: this.$t('Jobs.coachingExperiences7'),
        },
        {
          value: 'Mental Illness',
          label: this.$t('Jobs.coachingExperiences8'),
        },
        {
          value: 'Chronic Illness',
          label: this.$t('Jobs.coachingExperiences9'),
        },
      ],
      imagebanners: [
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/Banner_Adult_1-20220606.jpg',
          filename: 'images/Banner_Adult_1-20220606.jpg',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/Banner_Adult_2-20220606.jpg',
          filename: 'images/Banner_Adult_2-20220606.jpg',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/Banner_Elderly_1-20220606.jpg',
          filename: 'images/Banner_Elderly_1-20220606.jpg',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/Banner_Elderly_2-20220606.jpg',
          filename: 'images/Banner_Elderly_2-20220606.jpg',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/Banner_Family_1-20220606.jpg',
          filename: 'images/Banner_Family_1-20220606.jpg',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/Banner_Family_2-20220606.jpg',
          filename: 'images/Banner_Family_2-20220606.jpg',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/Banner_FunDay_1-20220606.jpg',
          filename: 'images/Banner_FunDay_1-20220606.jpg',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/Banner_FunDay_2-20220606.jpg',
          filename: 'images/Banner_FunDay_2-20220606.jpg',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/Banner_FunDay_3-20220606.jpg',
          filename: 'images/Banner_FunDay_3-20220606.jpg',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/Banner_FunDay_4-20220606.jpg',
          filename: 'images/Banner_FunDay_4-20220606.jpg',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/Banner_Kids_1-20220606.jpg',
          filename: 'images/Banner_Kids_1-20220606.jpg',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/Banner_Kids_2-20220606.jpg',
          filename: 'images/Banner_Kids_2-20220606.jpg',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/Banner_Online_1-20220606.jpg',
          filename: 'images/Banner_Online_1-20220606.jpg',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/Banner_Online_2-20220606.jpg',
          filename: 'images/Banner_Online_2-20220606.jpg',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/Banner_Sports_1-20220606.jpg',
          filename: 'images/Banner_Sports_1-20220606.jpg',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/Banner_Sports_2-20220606.jpg',
          filename: 'images/Banner_Sports_2-20220606.jp',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/Banner_Sports_3-20220606.jpg',
          filename: 'images/Banner_Sports_3-20220606.jpg',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/Banner_Sports_4-20220606.jpg',
          filename: 'images/Banner_Sports_4-20220606.jpg',
        },
      ],

      Location: [],
      areas: [],
      imageUrl: '',
      localFile: {},
      jobData: [],
      id: null,
      isFirstTermSelected: false,
      isSecondTermSelected: false,
      bannerRadio: '',
    }
  },
  created() {
    this.styleLang = window.localStorage.getItem('locale') || 'cn'
  },
  mounted() {
    this.getUserId()
    this.id = this.GetId()
    if (this.id) {
      this.getJob()
    }
    this.getArea()
    this.getSports()
    S3Api.refreshS3KeyAction().then((response) => {
      S3Api.saveS3KeyAction(response)
    })
  },
  methods: {
    GetId() {
      return window.location.hash.split('=')[1]
    },
    getUserId() {
      this.userId = window.sessionStorage.getItem('user_id')
    },
    getJob() {
      GetJob(this.id, this.userId).then((res) => {
        this.form = res.data
        if (res.data.user_id !== this.userId) {
          this.inputDisable = true
        }
        this.formatData()
        console.log(this.form)
      })
    },
    getArea() {
      let area
      GetArea().then((res) => {
        area = res.data
        area.map((one) => {
          this.areas = this.areas.concat(one.Areas)
        })
      })
    },
    getSports() {
      getSportTypes().then((res) => {
        this.sportType = res.data.data
      })
    },
    formatData() {
      this.date = []
      this.time = []
      if (this.form.starting_date) {
        this.date.push(this.form.starting_date)
        let date0 = this.form.starting_date.replace(/-/g, '').replace(/^(\d{2})(\d{2})(\d{4})$/, '$3-$2-$1')
        this.date[0] = date0
      }
      if (this.form.ending_date) {
        this.date.push(this.form.ending_date)
        let date1 = this.form.ending_date.replace(/-/g, '').replace(/^(\d{2})(\d{2})(\d{4})$/, '$3-$2-$1')
        this.date[1] = date1
      }
      if (this.form.class_duration_start) {
        this.time.push(this.form.class_duration_start)
        this.time[0] = this.form.class_duration_start
      }
      if (this.form.class_duration_end) {
        this.time.push(this.form.class_duration_end)
        this.time[1] = this.form.class_duration_end
      }
    },
    formatDandT() {
      if (this.date) {
        this.form.starting_date = this.date[0]
        this.form.ending_date = this.date[1]
      }
      if (this.time) {
        this.form.class_duration_start = this.time[0]
        this.form.class_duration_end = this.time[1]
      }
    },
    submit() {
      if (!this.isFirstTermSelected || !this.isSecondTermSelected) {
        this.$message({
          message: 'terms and condition is required',
          type: 'error',
        })
        return false
      }
      this.formatDandT()
      if (!this.form.banner) {
        this.form.banner = 'images/default.jpg'
      }
      this.form.coaching_experiences.toString()
      if (this.id) {
        delete this.form.index
        editJob(this.id, this.form)
          .then((res) => {
            this.$message({
              message: 'success',
              type: 'success',
            })
            this.$router.push('/coach-connect/opportunities')
          })
          .catch(() => {
            this.$message({
              message: 'fail',
              type: 'error',
            })
          })
      } else {
        submitJob(this.form)
          .then((res) => {
            this.$message({
              message: this.$t('CreateProfiles.message2'),
              type: 'success',
            })
            this.$router.push('/coach-connect/opportunities')
          })
          .catch(() => {
            this.$message({
              message: 'fail',
              type: 'error',
            })
          })
      }
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
      this.fileList.splice(this.fileList.indexOf(file), 1)
      this.form.banner = ''
      this.file = ''
    },
    handleChange(file, fileList) {
      this.isFileChange = true
      this.localFile = file.raw

      // 转换操作可以不放到这个函数里面，
      // 因为这个函数会被多次触发，上传时触发，上传成功也触发
      let reader = new FileReader()
      reader.readAsDataURL(this.localFile) // 这里也可以直接写参数event.raw
      reader.onload = () => {
        // console.log(reader.result)
      }
      let URL = window.URL || window.webkitURL
      this.imageUrl = URL.createObjectURL(file.raw)
      this.form.banner_url = ''
      console.log(this.imageUrl)
    },
    beforeUpload(file) {
      this.file = file
      this.fileList.push(file)
    },
    save() {
      this.dialogFormVisible = false
      this.BtnLoading = false
      for (let i = 0; i < this.imagebanners.length; i++) {
        if (this.imagebanners[i].url === this.bannerRadio) {
          this.form.banner = this.imagebanners[i].filename
          break
        }
      }
      this.form.banner_url = this.bannerRadio
      this.imageUrl = ''
    },
    uploadPhotos() {
      S3Api.uploadPhoto(this.file).then((res) => {
        this.BtnLoading = false
        this.form.banner = res.Key
        this.fileList = []
        this.dialogFormVisible = false
        this.isFileChange = false
        this.$message({
          message: 'success',
          type: 'success',
        })
      })
      console.log(this.form)
    },
    uploadPic() {
      this.BtnLoading = true
      this.$refs.upload.submit()
    },
  },
}
</script>
<style lang="scss" scoped>
.bannerwidth {
  max-width: 1000px;
  width: 100%;
}
.banner-con {
  position: relative;
  .top-left-con {
    width: 64%;
    height: 500px;
    background: #28327b;
    position: absolute;
    top: 0;
    left: 0;
    transform: skew(-15deg) translateX(-9%);
    z-index: 2;
    .line-one {
      width: 338px;
      height: 2px;
      background: #fff;
      position: absolute;
      top: 24%;
      right: 2%;
    }
    .line-two {
      width: 120px;
      height: 2px;
      background: #fff;
      position: absolute;
      top: 42%;
      right: 2%;
    }
    .banner-tit1 {
      width: auto;
      margin-top: 18%;
      margin-left: 2%;
      padding-left: 13%;
      text-align: left;
      font-size: 70px !important;
      color: #fff;
      transform: skew(6deg);
      font-weight: bold;
    }
    .banner-tit2 {
      width: auto;
      margin-top: -2%;
      margin-left: 4%;
      padding-left: 10%;
      text-align: left;
      font-size: 70px !important;
      color: #fff;
      transform: skew(6deg);
      font-weight: bold;
      // font-family: "NotoSansCJKtc-Black";
    }
    .bg-tit {
      margin: 200px auto;
      font-size: 75px;
      font-weight: bold;
      line-height: 75px;
      color: #ffffff;
      font-size: 70px !important;
      color: #fff;
      opacity: 1;

      transform: skew(13deg);
    }
    .bg-tit1 {
      margin-top: 150px;
      font-size: 75px;
      font-weight: bold;
      line-height: 75px;
      color: #ffffff;
      font-size: 70px !important;
      color: #fff;

      transform: skew(13deg);
    }
    .bg-tit2 {
      margin: 0 auto;
      font-size: 75px;
      font-weight: bold;
      line-height: 75px;
      color: #ffffff;
      font-size: 70px !important;
      color: #fff;

      transform: skew(13deg);
    }
    .more {
      position: absolute;
      bottom: 20px;
      right: 20px;
      color: #fff;
      transform: skew(15deg);
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .swiper {
    .swiper-pagination {
      display: none !important;
    }
    height: 620px;
    .swiper-item1 {
      position: relative;
      overflow: hidden;
      .box {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 60%;
        overflow: hidden;
        transform: skew(-15deg) translateX(12%);
        .img {
          position: absolute;
          left: 0;
          bottom: 0;
          height: 100%;
          width: 130%;
          transform: skew(15deg) translateX(-9%);
          object-fit: cover;
        }
      }
      .top-right-con {
        width: 40%;
        height: 9.1%;
        background: #f15922;
        position: absolute;
        top: 3%;
        right: 0;
        transform: skew(-15deg) translateX(2.5%);
      }
      .bottom-left-con {
        width: 43.5%;
        height: 15%;
        background: #f15922;
        position: absolute;
        bottom: 5%;
        left: 0;
        transform: skew(-15deg) translateX(-3%);
      }
    }
  }
}
.center-background {
  background: url('../assets/img/bg-blue.jpg') no-repeat;
  padding: 40px 0;
}
.radio-group {
  margin: 120px auto;
}
.createForm {
  .formgroup {
    margin: 0 40px 40px;
    padding: 40px;
    background-color: #fff;
    box-sizing: border-box;
    .group-name {
      margin-bottom: 10px;
      text-align: left;
      font-size: 22px;
      font-weight: bold;
      line-height: 28px;
      color: #28327b;
    }
    .col-class {
      margin: 10px 0;
    }
    .el-select {
      width: 100%;
    }
    .el-row {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .el-col {
      border-radius: 4px;
    }
    .grid-content {
      border-radius: 4px;
      min-height: 36px;
    }
    .row-bg {
      padding: 10px 0;
      background-color: #f9fafc;
    }
  }
  .upload {
    position: relative;
    background: #ffffff;
    border: 2px dashed #8f98dc;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 0 40px;
    .upload-text {
      font-size: 22px;
      font-weight: 600;
      line-height: 130px;
      color: #8f98dc;
    }
    .banner-url {
      width: 100%;
      height: 100%;
    }
    .plus-btn {
      display: block;
      position: absolute;
      right: 20px;
      font-weight: 400;
      color: #8f98dc;
      top: 50%;
      font-size: 20px;
      line-height: 50px;
      width: 50px;
      height: 50px;
      margin-top: -25px;
      background: #f8f8f8;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}
.submit-btn {
  width: 295px;
  margin: 20px auto 0;
  height: 65px;
  background: #f15922;
  border: 1px solid #f15922;
  border-radius: 4px;
  color: #fff;
}
::v-deep {
  .el-radio-button__inner {
    width: 357px;
    color: #f15922;
  }
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: #f15922;
    border-color: #f15922;
  }
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    -webkit-box-shadow: -1px 0 0 0 #f15922;
    box-shadow: -1px 0 0 0 #f15922;
  }
  .el-input__inner {
    width: 100%;
    background: #f8f8f8;
  }
  .el-textarea__inner {
    background: #f8f8f8;
  }
  .el-textarea__inner:focus {
    background: #fff;
    border-color: #8f98dc;
  }
  .el-input.is-active .el-input__inner,
  .el-input__inner:focus {
    background: #fff;
    border-color: #8f98dc;
  }
  .el-select .el-input.is-focus .el-input__inner {
    border-color: #8f98dc;
  }
  .el-select .el-input .el-select__caret {
    color: #f15922;
  }
  .el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
    color: #8f98dc !important;
  }
  .el-select-dropdown__item.selected {
    color: #8f98dc !important;
  }
  .el-radio-button:first-child .el-radio-button__inner {
    border-radius: 4px 0 0 4px;
  }
  .el-radio-button:last-child .el-radio-button__inner {
    border-radius: 0 4px 4px 0;
  }
  .el-range-input {
    background: #f8f8f8;
  }
  .el-range-editor.is-active,
  .el-range-editor.is-active:hover {
    border-color: #8f98dc;
  }
  .el-date-table td.end-date span,
  .el-date-table td.start-date span {
    background-color: #8f98dc;
  }
  .el-date-table td.today span {
    border-color: #8f98dc;
  }
  .el-checkbox__label {
    display: inline !important;
  }
}

.el-checkbox,
.el-checkbox__input {
  position: relative;
  white-space: normal !important;
  margin-bottom: 20px;
}
.condition {
  text-align: left;
  padding: 40px;
  .verify {
    font-size: 20px;
    margin-top: 20px;
  }
}
</style>
<style lang="scss">
@media screen and (max-width: 480px) {
  #Opppryunities {
    .swiper {
      height: 500px;
      .swiper-item1 {
        .top-right-con {
          width: 40.5%;
          height: 14%;
          transform: skew(-15deg) translateX(7%);
        }
        .box {
          height: 100%;
          width: 53%;
          right: -3%;
          .img {
            height: 100%;
            width: 120%;
            right: 0%;
          }
        }
      }
    }
    .top-left-con {
      width: 65%;
      left: -3%;
      padding-left: 5%;
      .bg-tit1 {
        font-size: 35px !important;
        margin-top: 15% !important;
        padding-left: 30%;
      }
      .bg-tit2 {
        font-size: 35px !important;
        margin-top: 40% !important;
        padding-left: 30%;
      }
    }
  }
}
.banner-choose {
  width: 100%;
}

.image-radios {
  padding-top: 2%;
  padding-left: 2%;
  span.el-radio-button__inner {
    padding: 2px;
    width: 100%;
  }
}
</style>
